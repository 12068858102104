import { Link, Location, useLocation } from 'react-router-dom'
import { CompanyTypes } from '../../../../generated/graphql'
import CapTableMenu from '../components/CapTableMenu'
import ModellingMenu from '../components/ModellingMenu'

function CompanyProfileNavigationMenu() {
  const location = useLocation()
  const companyType = location.pathname.split('/')[3] || ('' as CompanyTypes)
  const id = location.pathname.split('/')[4] || ''

  return (
    <div className='d-flex overflow-auto px-10 header-bg'>
      <ul className='nav nav-stretch nav-line-tabs fs-4 fw-500 flex-nowrap border-bottom-transparent h-45px'>
        {(companyType as CompanyTypes) === CompanyTypes.Hissa && getHissaCompanyMenus(id, location)}
        {(companyType as CompanyTypes) !== CompanyTypes.Hissa &&
          getNonHissaCompanyMenus(id, location)}
      </ul>
    </div>
  )
}

function getHissaCompanyMenus(id: string, location: Location) {
  return (
    <>
      <li className='nav-item'>
        <Link
          className={
            `nav-link me-6 ` +
            (location.pathname.includes('/company/summary') && 'active text-primary ')
          }
          to={`/company/summary/HISSA/${id}`}
        >
          Summary
        </Link>
      </li>
      <li className='nav-item'>
        <Link
          className={
            `nav-link text-active-primary me-6 ` +
            (location.pathname === '/company/captable' && 'active text-primary')
          }
          to={`/company/captable/HISSA/${id}`}
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='bottom-start'
          data-kt-menu-flip='top-end'
        >
          <CapTableMenu companyId={id} companyType={CompanyTypes.Hissa} />
          CapTable
          <span className='nav-arrow'></span>
        </Link>
      </li>
      <li className='nav-item'>
        <Link
          className={
            `nav-link text-active-primary me-6 ` +
            (location.pathname.includes('modelling') && 'active text-primary')
          }
          to={`/company/exitmodelling/HISSA/${id}`}
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='bottom-start'
          data-kt-menu-flip='top-end'
        >
          <ModellingMenu companyId={id} companyType={CompanyTypes.Hissa} />
          Modelling
          <span className='nav-arrow'></span>
        </Link>
      </li>
      <li className='nav-item'>
        <Link
          className={
            `nav-link me-6 ` +
            (location.pathname.includes('/company/documents') && 'active text-primary ')
          }
          to={`/company/documents/HISSA/${id}`}
        >
          Documents
        </Link>
      </li>
      <li className='nav-item'>
        <Link
          className={
            `nav-link me-6 ` +
            (location.pathname.includes('/company/misreport') && 'active text-primary ')
          }
          to={`/company/misreport/HISSA/${id}`}
        >
          MIS Report
        </Link>
      </li>
    </>
  )
}

function getNonHissaCompanyMenus(id: string, location: Location) {
  return (
    <>
      <li className='nav-item'>
        <Link
          className={
            `nav-link me-6 ` +
            (location.pathname.includes('/company/summary') && 'active text-primary ')
          }
          to={`/company/summary/DIRECT/${id}`}
        >
          Summary
        </Link>
      </li>
      <li className='nav-item'>
        <Link
          className={
            `nav-link me-6 ` +
            (location.pathname.includes('/company/documents') && 'active text-primary ')
          }
          to={`/company/documents/DIRECT/${id}`}
        >
          Documents
        </Link>
      </li>
      <li className='nav-item'>
        <Link
          className={
            `nav-link me-6 ` +
            (location.pathname.includes('/company/misreport') && 'active text-primary ')
          }
          to={`/company/misreport/DIRECT/${id}`}
        >
          MIS Report
        </Link>
      </li>
    </>
  )
}

export default CompanyProfileNavigationMenu
